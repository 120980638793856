import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import Forms from '@/store/modules/forms'
import { HeaderGral, HeaderFileBoundaryGral } from '@/store/modules/config'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    urls: null,
    logo: null,
    login: null,
    dialogMain: {
      dialog: false, type: '', form: 'form', withWindows: 500, title: '', body: {}
    },
    options: {},
    model: {},

    object: {},
    objectTwo: {},
    objectThree: {},
    objectDelete: {},
    objects: [],
    objectsTwo: [],
    objectPdf: {},

    reload: {},
    importData: {},
    btnLoading: false,
    LoadingDialog: false
  },
  getters: {
    get_urls (state) { return state.urls },
    get_logo (state) { return state.logo },

    get_login (state) { return state.login },
    get_dialogMain (state) { return state.dialogMain },
    get_options (state) { return state.options },
    get_model (state) { return state.model },

    get_object (state) { return state.object },
    get_objectTwo (state) { return state.objectTwo },
    get_objectThree (state) { return state.objectThree },

    get_objectDelete (state) { return state.objectDelete },
    get_objects (state) { return state.objects },
    get_objectsTwo (state) { return state.objectsTwo },
    get_objectPdf (state) { return state.objectPdf },

    get_reload (state) { return state.reload },
    get_importData (state) { return state.importData },
    get_shoeBtnLoading (state) { return state.btnLoading },
    get_showLoadingDialog (state) { return state.LoadingDialog }

  },
  mutations: {
    SHOW_DIALOG_MAIN (state, data) {
      state.dialogMain.dialog = true
      state.dialogMain.type = data.type
      state.dialogMain.form = (data.form) ? data.form : 'form'
      state.dialogMain.withWindows = (data.with) ? data.with : 500
      state.dialogMain.title = (data.title) ? data.title : ''
      state.dialogMain.body = (data.body) ? data.body : {}
    },
    HIDDE_DIALOG_MAIN (state) {
      state.dialogMain.dialog = false
      state.dialogMain.type = ''
      state.dialogMain.form = 'form'
      state.dialogMain.withWindows = 500
      state.dialogMain.title = ''
      state.dialogMain.body = {}
    },
    SHOW_LOADING_MAIN (state, data) {
      state.btnLoading = true
    },
    HIDDE_LOADING_MAIN (state, data) {
      state.btnLoading = false
    },
    LOADING_MAIN_DIALOG (state, active) {
      state.LoadingDialog = active
    },

    GET_URLS_SERVER (state, urls) {
      state.urls = {}
      state.urls = urls
    },
    GET_URL_LOGO (state, logo) {
      state.logo = {}
      state.logo = logo
    },
    OPTION_NEXT_ACTION (state, options) {
      state.options = {}
      state.options = options
    },
    EXECUTE_ACTION (state, action) {
      state.model = {}
      state.model = action
    },

    POST_LOGIN_SERVER (state, data) {
      state.login = {}
      state.login = data
    },

    SET_DATA (state, data) {
      let findobject = -1
      state.objects.map((el, i) => {
        if (el.id === data.id) {
          findobject = i
        }
      })
      if (findobject !== -1) Object.assign(state.objects[findobject], data)
      else state.objects.push(data)
    },
    SET_DATA_TWO (state, data) {
      let findobject = -1
      state.objectsTwo.map((el, i) => {
        if (el.id === data.id) { findobject = i }
      })
      if (findobject !== -1) Object.assign(state.objectsTwo[findobject], data)
      else state.objectsTwo.push(data)
    },
    SET_RELOAD_DATA (state, data) {
      state.reload = data
    },
    SET_DELETE_DATA (state, data) {
      const rows = state.objects.filter(el => el.id !== parseInt(data.id))
      state.objects = rows
    },
    SET_DELETE_OBJECT_DATA (state, data) {
      state.objectDelete = data
    },

    CLEAR_DATA (state) {
      state.object = {}
    },
    CLEAR_LIST_DATA (state) {
      state.objects = []
    },

    GET_LIST_DATA_SERVICES (state, data) {
      state.objects = []
      state.objects.push(...data)
    },
    GET_LIST_DATA_TWO_SERVICES (state, data) {
      state.objectsTwo = []
      state.objectsTwo.push(...data)
    },

    DATA_SERVICES (state, data) {
      state.object = {}
      state.object = data
    },
    DATA_TWO_SERVICES (state, data) {
      state.objectTwo = {}
      state.objectTwo = data
    },
    DATA_THREE_SERVICES (state, data) {
      state.objectThree = {}
      state.objectThree = data
    },
    POST_DATA_IMPORT_SERVER (state, data) {
      state.importData = {}
      state.importData = data
    },
    OBJECT_DATA_PDF_SERVICES (state, data) {
      state.objectPdf = {}
      state.objectPdf = data
    }
  },
  actions: {
    GETUrlServices ({ commit }) {
      axios.get('/routes').then((response) => {
        commit('GET_URLS_SERVER', response.data)
      }).catch((error) => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },
    POSTLoginService ({ commit }, action) {
      axios.post(`${action.url.uri}`, action.data).then((response) => {
        commit('POST_LOGIN_SERVER', response.data)
      }).catch((error) => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },
    POSTLogoutService ({ commit }, action) {
      const header = HeaderGral()
      axios.get(`${action.url.uri}`, header).then(response => {
        commit('POST_LOGIN_SERVER', response.data)
      })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    GETUrlLogoSistema ({ commit }) {
      axios.get('/logo/login').then((response) => {
        commit('GET_URL_LOGO', response.data)
      }).catch((error) => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },

    GETValidateTokenService ({ commit }, action) {
      const header = HeaderGral()
      axios.get(`${action.url.uri}`, header).then(response => {
        commit('POST_LOGIN_SERVER', response.data)
      })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    // Actions Get´s
    GETListObjectsService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      } else if (action.addicional) {
        url = action.url.uri + action.addicional
      }
      axios.get(`${url}`, header).then((response) => {
        commit('GET_LIST_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    GETListObjectsTwoService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      } else if (action.addicional) {
        url = action.url.uri + action.addicional
      }
      axios.get(`${url}`, header).then((response) => {
        commit('GET_LIST_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    GETObjectService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      } else if (action.params_url) {
        url = action.url.uri
        url += action.params_url
      }
      axios.get(`${url}`, header).then((response) => {
        commit('DATA_SERVICES', response.data)
      })
        .catch((error) => {
          console.log('error-->>', error)
          commit('DATA_SERVICES', error)
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    GETObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      } else if (action.params_url) {
        url = action.url.uri
        url += action.params_url
      }
      axios.get(`${url}`, header).then((response) => {
        commit('DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    GETExportPdftSerice ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      }
      if (action.addparams) {
        url = `${action.url.uri}${action.addparams}`
      }
      axios.get(`${url}`, header).then(response => {
        const file = response.data
        const linkSource = `data:application/pdf;base64,${file.pdf}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = file.nombre
        downloadLink.click()

        const pdfWindow = window.open('')
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(file.pdf) + "'></iframe>")
      }).catch(error => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },
    GETObjectPdftService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      }
      if (action.addparams) {
        url = `${action.url.uri}${action.addparams}`
      }
      axios.get(`${url}`, header).then(response => {
        commit('OBJECT_DATA_PDF_SERVICES', response.data)
      }).catch(error => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },
    // Actions Post´s
    POSTListObjectsService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header).then((response) => {
        commit('GET_LIST_DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTListObjectstwoService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header).then((response) => {
        commit('GET_LIST_DATA_TWO_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTObjectsService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header).then((response) => {
        commit('DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTObjectsThreeService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header).then((response) => {
        commit('DATA_THREE_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTObjectService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header).then((response) => {
        commit('DATA_SERVICES', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTImportDataService ({ commit }, action) {
      const boundary = `; boundary=${action.data._boundary}`
      const header = HeaderFileBoundaryGral(boundary)
      axios.post(`${action.url}`, action.data, header).then(response => {
        commit('POST_DATA_IMPORT_SERVER', response.data)
      }).catch(error => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },
    // Actions Put´s
    PUTDisableEnableObjectService ({ commit }, action) {
      const header = HeaderGral()
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, action.params)
      }
      axios.put(`${url}`, action.data, header).then((response) => {
        commit('SET_DATA', response.data)
      })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    // Actions Deleted´s
    DELETEObjectService ({ commit }, action) {
      const header = HeaderGral()
      let params = ''
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, '')
        params = action.params
      }
      axios.delete(`${url}${params}`, header).then(response => {
        commit('SET_DELETE_DATA', response.data)
      })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    DELETEObjectTwoService ({ commit }, action) {
      const header = HeaderGral()
      let params = ''
      let url = action.url.uri
      if (action.params) {
        url = action.url.uri.replace(action.replace, '')
        params = action.params
      }
      axios.delete(`${url}${params}`, header).then(response => {
        commit('SET_DELETE_OBJECT_DATA', response.data)
      })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },

    // Actions Export´s
    ExportDataService ({ commit }, action) {
      const header = HeaderGral()
      const url = action.url.uri
      axios.defaults.headers = header.headers
      axios.post(`${url}`, action.data, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        let tipo = 'gral'
        if (action.data.facturado) {
          if (action.data.facturado === 'si') tipo = 'Faturados'
          else if (action.data.facturado === 'no') tipo = 'No_Faturados'
          else tipo = 'Facturados_Y_NoFacturados'
        }
        link.download = `${action.data.nombre}_${tipo}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
        commit('LOADING_MAIN_DIALOG', false)
      }).catch(error => {
        if (error == null) {
          Vue.swal({
            type: 'error',
            title: 'Ooops!',
            text: 'Problemas con el servidor, contactar al sistemas !!'
          })
        }
      })
    },

    // Actions Main Process
    GETOptionService ({ commit }, action) {
      const header = HeaderGral()
      if (action.params) {
        action.url.uri = action.url.uri.replace('[/{status_id}]', action.params)
      }
      axios.get(`${action.url.uri}`, header)
        .then((response) => {
          commit('OPTION_NEXT_ACTION', response.data)
        })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTExecuteService ({ commit }, action) {
      const header = HeaderGral()
      axios.post(`${action.url.uri}`, action.data, header)
        .then((response) => {
          commit('EXECUTE_ACTION', response.data)
        })
        .catch((error) => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    },
    POSTExecuteServiceMultipart ({ commit }, action) {
      const boundary = `; boundary=${action.data._boundary}`
      const header = HeaderFileBoundaryGral(boundary)
      axios.post(`${action.url.uri}`, action.data, header).then(response => {
        commit('EXECUTE_ACTION', response.data)
      })
        .catch(error => {
          if (error == null) {
            Vue.swal({
              type: 'error',
              title: 'Ooops!',
              text: 'Problemas con el servidor, contactar al sistemas !!'
            })
          }
        })
    }

  },
  modules: {
    Forms
  }
})
